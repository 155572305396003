import { BSC,BSCTestnet,Goerli,Mainnet } from "@usedapp/core";
import { Contract } from "ethers";

import {PresaleABI} from "./PresaleABI";

export const TokenName = "Brunsi Coin";
export const TokenSymbol = "BRaVO";

export const useSupportedNetworks3 = {
  //BSC MAINNET
  [BSC.chainId]: {
    token: {
      contractAddress: "0x7f04C94d1cad56aA31f6ECcc65574807e464e50f",
      name: TokenName,
      symbol: TokenSymbol,
      decimals: 18,
    },
    native: {
      contractAddress: "",
      name: BSC?.nativeCurrency?.name,
      symbol: BSC?.nativeCurrency?.symbol,
      decimals: BSC?.nativeCurrency?.decimals,
    },
    presaleAddress: "0x542F6f6C688ec14B750E68C7C7383276A7d19100",
    presaleContractInterface: new Contract(
      "0x542F6f6C688ec14B750E68C7C7383276A7d19100",
      PresaleABI
    ),
    explorerLink: {
      name: "bscscan.com",
    },
    nativeFunctions: BSC,
  },
  // [Goerli.chainId]: {
  //   token: {
  //     contractAddress: "0x4686417483Cca148c2cd3D1eaadF5e6b6438f3D6",
  //     name: TokenName,
  //     symbol: TokenSymbol,
  //     decimals: 18,
  //   },
  //   native: {
  //     contractAddress: "",
  //     name: Goerli?.nativeCurrency?.name,
  //     symbol: Goerli?.nativeCurrency?.symbol,
  //     decimals: Goerli?.nativeCurrency?.decimals,
  //   },
  //   presaleAddress: "0x6fE361e1180487b63Cd9C0e93b665859ac5158d8",
  //   presaleContractInterface: new Contract(
  //     "0x6fE361e1180487b63Cd9C0e93b665859ac5158d8",
  //     PresaleABI
  //   ),
  //   explorerLink: {
  //     name: "bscscan.com",
  //   },
  //   nativeFunctions: Goerli,
  // },
};
