import React, { useState, useEffect } from "react";
import {
  Box,
  ChakraProvider,
  Heading,
  theme,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { Footer, Nav, SwapUI, SwapUI2, SwapUI3, SwapUI4 } from "./components";
import HowToBuy from "./components/HowToBuy";
import { TokenInfo } from "./components/TokenInfo";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex
} from "@chakra-ui/react";
import "./App.css";

const Countdown = () => {
  const [remainingTime, setRemainingTime] = useState("");
  useEffect(() => {
    const countdownDate = new Date("2023-11-23T00:00:00Z").getTime(); // Set your countdown date and time here in UTC format
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setRemainingTime("Countdown Expired");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setRemainingTime(
          `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [days, hours, minutes, seconds] = remainingTime
    .split(",")
    .map((item) => item.trim().split(" ")[0]);

  return (
    <Box textAlign="center" pb={"30px"}>
      <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mb={4}>
        Buy now! Presale ends in:
      </Heading>
      <Box display="flex" justifyContent="space-between">
        <Box
          borderRadius="md"
          bg="#00002e"
          color="#90cdf4"
          p={2}
          textAlign="center"
          flex="1"
          mx={2}
          borderWidth="1px"
          borderColor="#90cdf4"
          
          minWidth={{ base: "75px", md: "85px" }}
        >
          <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
            Days
          </Heading>
          <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
            {days}
          </Heading>
        </Box>
        <Box
          borderRadius="md"
          bg="#00002e"
          color="#90cdf4"
          p={2}
          textAlign="center"
          flex="1"
          mx={2}
          borderWidth="1px"
          borderColor="#90cdf4"
          minWidth={{ base: "75px", md: "85px" }}
        >
          <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
            Hours
          </Heading>
          <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
            {hours}
          </Heading>
        </Box>
        <Box
          borderRadius="md"
          bg="#00002e"
          color="#90cdf4"
          p={2}
          textAlign="center"
          flex="1"
          mx={2}
          borderWidth="1px"
          borderColor="#90cdf4"
          minWidth={{ base: "75px", md: "85px" }}
        >
          <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
            Minutes
          </Heading>
          <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
            {minutes}
          </Heading>
        </Box>
        <Box
          borderRadius="md"
          bg="#00002e"
          color="#90cdf4"
          p={2}
          textAlign="center"
          flex="1"
          mx={2}
          borderWidth="1px"
          borderColor="#90cdf4"
          minWidth={{ base: "75px", md: "85px" }}
        >
          <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
            Seconds
          </Heading>
          <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
            {seconds}
          </Heading>
        </Box>
      </Box>
    </Box>
  );
};

export const App = () => (
  <VStack w="full" className="main-body">
    <Nav />
    <VStack w="full" pt={10} pb={20}>
      <Countdown />
      <Tabs variant="soft-rounded" colorScheme="blue">
        <TabList justifyContent="center" mb={5}>
          <Tab className="tabpanel-tab">
            <img src="images/SOPO.png" alt="" className="tab-img" />
            SOPO
          </Tab>
          <Tab className="tabpanel-tab">
            <img src="images/BOUTA.png" alt="" className="tab-img" />
            BOUTA
          </Tab>
          <Tab className="tabpanel-tab">
            <img src="images/BRAVO.png" alt="" className="tab-img" />
            BRUNSI
          </Tab>
          <Tab className="tabpanel-tab">
            <img src="images/BORDO.png" alt="" className="tab-img" />
            MONEYHOND
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex justify="center" direction={{ base: "column", md: "row" }}>
              <Box mb={{ base: 4, md: 0 }} mr={{ base: 0, md: 4 }}>
                <SwapUI />
              </Box>
              <Box ml={{ base: 0, md: 4 }}>
                <HowToBuy />
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex justify="center" direction={{ base: "column", md: "row" }}>
              <Box mb={{ base: 4, md: 0 }} mr={{ base: 0, md: 4 }}>
                <SwapUI2 />
              </Box>
              <Box ml={{ base: 0, md: 4 }}>
                <HowToBuy />
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex justify="center" direction={{ base: "column", md: "row" }}>
              <Box mb={{ base: 4, md: 0 }} mr={{ base: 0, md: 4 }}>
                <SwapUI3 />
              </Box>
              <Box ml={{ base: 0, md: 4 }}>
                <HowToBuy />
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex justify="center" direction={{ base: "column", md: "row" }}>
              <Box mb={{ base: 4, md: 0 }} mr={{ base: 0, md: 4 }}>
                <SwapUI4 />
              </Box>
              <Box ml={{ base: 0, md: 4 }}>
                <HowToBuy />
              </Box>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex justify="center" align="end" direction={{ base: "column", md: "row" }}>
      <img src="images/EQB LOGO.png" alt="" className="equality-builder-logo" />
      <img src="images/aexlogo.png" alt="" className="equality-builder-logo" />
      </Flex>
      <TokenInfo />
    </VStack>

    <Footer />
  </VStack>
);
