import { Box, Flex, Image, Text, UnorderedList, ListItem } from '@chakra-ui/react';

export const TokenInfo = () => {
  return (
    <Flex flexWrap="wrap" justifyContent="center">
      <Box p={4} width={{ base: '100%', md: '50%' ,lg:'50%'}}>
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Image src="images/SOPO.png" alt="Image 1" maxW="150px" />
          <Text fontWeight="bold" mt={2}>SOPO</Text>
          <UnorderedList mt={2} textAlign="left"fontSize={{base:'14px', md:'16px'}}>
                <ListItem>0x45a7502E803A64944573Bc6Ae22d4f63547D755D</ListItem>
                <ListItem>BTC Rewards On Holding Over 1,200,000 SOPO</ListItem>
                <ListItem>600,000,000,000 Supply</ListItem>
                <ListItem>Rewards automatically added to wallet</ListItem>
                <ListItem>Will trade on AEXCElite.Finance 72H after Pre-sale Ends</ListItem>
              </UnorderedList>
        </Flex>
      </Box>

      <Box p={4} width={{ base: '100%', md: '50%' ,lg:'50%'}}>
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Image src="images/BOUTA.png" alt="Image 2" maxW="150px" />
          <Text fontWeight="bold" mt={2}>BOUTA</Text>
          <UnorderedList mt={2} textAlign="left" fontSize={{base:'14px', md:'16px'}}>
                <ListItem>0xa401a099b1C643DE506B49C6A954F4432Aa6B7cE</ListItem>
                <ListItem>USDT Rewards On Holding Over 800,000 BOUTA</ListItem>
                <ListItem>600,000,000,000 Supply</ListItem>
                <ListItem>Rewards automatically added to wallet</ListItem>
                <ListItem>Will trade on AEXCElite.Finance 72H after Pre-sale Ends</ListItem>
              </UnorderedList>
        </Flex>
      </Box>

      <Box p={4} width={{ base: '100%', md: '50%' ,lg:'50%'}}>
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Image src="images/BRAVO.png" alt="Image 3" maxW="150px" />
          <Text fontWeight="bold" mt={2}>BRUNSI</Text>
          <UnorderedList mt={2} textAlign="left"fontSize={{base:'14px', md:'16px'}}>
                <ListItem>0x7f04C94d1cad56aA31f6ECcc65574807e464e50f</ListItem>
                <ListItem>PAX Gold Rewards On Holding Over 800,000 BRUNSI</ListItem>
                <ListItem>600,000,000,000 Supply</ListItem>
                <ListItem>Rewards automatically added to wallet</ListItem>
                <ListItem>Will trade on AEXCElite.Finance 72H after Pre-sale Ends</ListItem>
              </UnorderedList>
        </Flex>
      </Box>

      <Box p={4} width={{ base: '100%', md: '50%' ,lg:'50%'}}>
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Image src="images/BORDO.png" alt="Image 4" maxW="150px" />
          <Text fontWeight="bold" mt={2}>MONEYHOND</Text>
          <UnorderedList mt={2} textAlign="left"fontSize={{base:'14px', md:'16px'}}>
                <ListItem>0xcd30F85fD5B454B9AB9FD1c7b9Ef7879C9dfF083</ListItem>
                <ListItem>DOGE Rewards On Holding Over 400,000 MONEYHOND</ListItem>
                <ListItem>600,000,000,000 Supply</ListItem>
                <ListItem>Rewards automatically added to wallet</ListItem>
                <ListItem>Will trade on AEXCElite.Finance 72H after Pre-sale Ends</ListItem>
              </UnorderedList>
        </Flex>
      </Box>
    </Flex>
  );
};

export default TokenInfo;
