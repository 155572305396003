import { useCall, useEthers } from "@usedapp/core";
import { ethers,BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useSupportedNetworks4 } from "../constants/SupportedNetworks4";
import {PresaleABI} from "./PresaleABI";
export const useCallHook4 = (
  functionName: string,
  arg: any[]
): BigNumber[] | undefined => {
  const { chainId } = useEthers();
  const currentNetwork = useSupportedNetworks4[chainId!];

  const { value, error } =
    useCall(
      currentNetwork?.presaleAddress && {
        contract: currentNetwork?.presaleContractInterface,
        method: functionName,
        args: arg ?? [],
      }
    ) ?? {};
  if (error) {
    console.error("Presale Hook Errors", error.message);
  }

  return value;
};

export const usePresalePrice4 = () => {
  const value: BigNumber[] | undefined = useCallHook4("getPrice", []);
  const valueFormatted = value ? Number(formatEther(value?.[0])) : 0;
  return valueFormatted;
};
export const useBNBcollected =  async (): Promise<BigNumber | undefined> => {
  const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");

  const contractAddress = "0x1E56876D04cda661E64a4ccec8dFc3F7a7f74d8E";

  try {
    

    const contract = new ethers.Contract(contractAddress, PresaleABI, provider);
    const [ethCollected] = await contract.getPresaleAnalytics();
    return ethCollected;
  } catch (error) {
    console.error("Presale Analytics Error", error);
    return undefined;
  }
};