import { BSC,BSCTestnet,Goerli,Mainnet } from "@usedapp/core";
import { Contract } from "ethers";

import {PresaleABI} from "./PresaleABI";

export const TokenName = "Moneyhond";
export const TokenSymbol = "Bordo";

export const useSupportedNetworks4 = {
  //BSC MAINNET
  [BSC.chainId]: {
    token: {
      contractAddress: "0xcd30F85fD5B454B9AB9FD1c7b9Ef7879C9dfF083",
      name: TokenName,
      symbol: TokenSymbol,
      decimals: 18,
    },
    native: {
      contractAddress: "",
      name: BSC?.nativeCurrency?.name,
      symbol: BSC?.nativeCurrency?.symbol,
      decimals: BSC?.nativeCurrency?.decimals,
    },
    presaleAddress: "0x1E56876D04cda661E64a4ccec8dFc3F7a7f74d8E",
    presaleContractInterface: new Contract(
      "0x1E56876D04cda661E64a4ccec8dFc3F7a7f74d8E",
      PresaleABI
    ),
    explorerLink: {
      name: "bscscan.com",
    },
    nativeFunctions: BSC,
  },
  // [Goerli.chainId]: {
  //   token: {
  //     contractAddress: "0x2dE3E1f5ec1BCC5dBC6F9706c1c8f7a141078eBa",
  //     name: TokenName,
  //     symbol: TokenSymbol,
  //     decimals: 18,
  //   },
  //   native: {
  //     contractAddress: "",
  //     name: Goerli?.nativeCurrency?.name,
  //     symbol: Goerli?.nativeCurrency?.symbol,
  //     decimals: Goerli?.nativeCurrency?.decimals,
  //   },
  //   presaleAddress: "0xC6bCB22FE15a492B51f66A4016aF4723567945e7",
  //   presaleContractInterface: new Contract(
  //     "0xC6bCB22FE15a492B51f66A4016aF4723567945e7",
  //     PresaleABI
  //   ),
  //   explorerLink: {
  //     name: "bscscan.com",
  //   },
  //   nativeFunctions: Goerli,
  // },
};
