import { BSC, BSCTestnet,Goerli,Mainnet } from "@usedapp/core";
import { Contract } from "ethers";

import {PresaleABI} from "./PresaleABI";

export const TokenName = "Surinam Origin Peoples Ot";
export const TokenSymbol = "SOPO";

export const useSupportedNetworks = {
  // BSC MAINNET
  [BSC.chainId]: {
    token: {
      contractAddress: "0x45a7502E803A64944573Bc6Ae22d4f63547D755D",
      name: TokenName,
      symbol: TokenSymbol,
      decimals: 18,
    },
    native: {
      contractAddress: "",
      name: BSC?.nativeCurrency?.name,
      symbol: BSC?.nativeCurrency?.symbol,
      decimals: BSC?.nativeCurrency?.decimals,
    },
    presaleAddress: "0x9dDb0248c7bDD91785A301Cc0D9d7011033Ca30e",
    presaleContractInterface: new Contract(
      "0x9dDb0248c7bDD91785A301Cc0D9d7011033Ca30e",
      PresaleABI
    ),
    explorerLink: {
      name: "bscscan.com",
    },
    nativeFunctions: BSC,
  },
  // [Goerli.chainId]: {
  //   token: {
  //     contractAddress: "0x5F01eA81C657939D6fF4C22E453D26A90B656645",
  //     name: TokenName,
  //     symbol: TokenSymbol,
  //     decimals: 18,
  //   },
  //   native: {
  //     contractAddress: "",
  //     name: Goerli?.nativeCurrency?.name,
  //     symbol: Goerli?.nativeCurrency?.symbol,
  //     decimals: Goerli?.nativeCurrency?.decimals,
  //   },
  //   presaleAddress: "0x986b1ef214D59B513b7B5401B45a1f3b05E5a43A",
  //   presaleContractInterface: new Contract(
  //     "0x986b1ef214D59B513b7B5401B45a1f3b05E5a43A",
  //     PresaleABI
  //   ),
  //   explorerLink: {
  //     name: "bscscan.com",
  //   },
  //   nativeFunctions: Goerli,
  // },
};
