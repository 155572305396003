import { Box, Button, Flex, HStack, IconButton, Spacer, useColorModeValue, useDisclosure,Collapse } from "@chakra-ui/react";
import { Logo } from "../Logo";
import { ConnectWalletButton } from "../ConnectWalletButton/ConnectWalletButton";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

interface NavItemProps {
  href: string;
  children: React.ReactNode;
}

export const Nav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box as="nav" bgColor="transparent" py={4} width={"100%"} className="nav-main" >
      <Flex
        maxW="100%"
        mx="auto"
        px={4}
        align="center"
        justify="space-between"
      >
        <Logo />

        {/* Hamburger Icon */}
        <IconButton
          display={{ base: "flex", md: "none" }}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          variant="ghost"
          aria-label="Toggle Navigation"
          onClick={isOpen ? onClose : onOpen}
        />

        {/* Links */}
        <HStack
          as="ul"
          spacing={4}
          pl={8}
          listStyleType="none"
          display={{ base: isOpen ? "flex" : "none", md: "flex" }}
          position={{ base: "absolute", md: "relative" }}
          flex={1} // Occupy available space
          flexDirection={{ base: "column", md: "row" }} 
          top={{ base: "83px", md: "initial" }} 
          left={{ base: "0", md: "initial" }}
          justify={{ base: "flex-start", md: "flex-start" }} 
          background={{ base: "#00002e", md: "transparent" }}
          align={{ base: "flex-start", md: "center" }}
          width={{ base: "100%", md: "auto" }}
        >
          <NavItem href="https://aexcelite.io/mint">EqualityBuilders-NFT-4-Good-Mint-Now</NavItem>
          <NavItem href="https://aexcelite.finance/" >Swap-4-GooD</NavItem>
        </HStack>

        {/* Connect Wallet Button */}
        <ConnectWalletButton />
      </Flex>
    </Box>
  );
};

const NavItem = ({ href, children }: NavItemProps) => {
  return (
    <Box as="li">
      <a target="_blank" className="nav-link-custom" href={href}>{children}</a>
    </Box>
  );
};
