import { ChakraProvider, ColorModeScript, theme,extendTheme } from "@chakra-ui/react";
import { BSCTestnet, Config, DAppProvider,Goerli,Mainnet,BSC } from "@usedapp/core";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { getDefaultProvider } from 'ethers'
import "./App.css";
const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  
}

const themee = extendTheme({ config });
const dappConfig: Config = {
  readOnlyUrls: {
    [BSC.chainId]: "https://bsc-dataseed.binance.org/", // BSC Mainnet
    // [Goerli.chainId]: getDefaultProvider('goerli'),
  },
};

root.render(
  <React.StrictMode>
    {/* <ColorModeScript /> */}
    <ChakraProvider theme={themee}>
      <DAppProvider config={dappConfig}>
        <App />
      </DAppProvider>
    </ChakraProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
