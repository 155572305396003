import { BSC,BSCTestnet,Goerli,Mainnet } from "@usedapp/core";
import { Contract } from "ethers";

import {PresaleABI} from "./PresaleABI";

export const TokenName = "Bouta Coin";
export const TokenSymbol = "DDB";

export const useSupportedNetworks2 = {
  //BSC MAINNET
  [BSC.chainId]: {
    token: {
      contractAddress: "0xa401a099b1C643DE506B49C6A954F4432Aa6B7cE",
      name: TokenName,
      symbol: TokenSymbol,
      decimals: 18,
    },
    native: {
      contractAddress: "",
      name: BSC?.nativeCurrency?.name,
      symbol: BSC?.nativeCurrency?.symbol,
      decimals: BSC?.nativeCurrency?.decimals,
    },
    presaleAddress: "0x4b3C0B03ca2F993747856aa8753A8b8DEf3A1e52",
    presaleContractInterface: new Contract(
      "0x4b3C0B03ca2F993747856aa8753A8b8DEf3A1e52",
      PresaleABI
    ),
    explorerLink: {
      name: "bscscan.com",
    },
    nativeFunctions: BSC,
  },
  // [Goerli.chainId]: {
  //   token: {
  //     contractAddress: "0x986D2afF81460a10a7Ab40534C90222387f2BE80",
  //     name: TokenName,
  //     symbol: TokenSymbol,
  //     decimals: 18,
  //   },
  //   native: {
  //     contractAddress: "",
  //     name: Goerli?.nativeCurrency?.name,
  //     symbol: Goerli?.nativeCurrency?.symbol,
  //     decimals: Goerli?.nativeCurrency?.decimals,
  //   },
  //   presaleAddress: "0x6DFC3F8304558a9f185C7906A52CD29A647C75E0",
  //   presaleContractInterface: new Contract(
  //     "0x6DFC3F8304558a9f185C7906A52CD29A647C75E0",
  //     PresaleABI
  //   ),
  //   explorerLink: {
  //     name: "bscscan.com",
  //   },
  //   nativeFunctions: Goerli,
  // },
};
