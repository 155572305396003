import { useCall, useEthers } from "@usedapp/core";
import { ethers,BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useSupportedNetworks3 } from "../constants/SupportedNetworks3";
import {PresaleABI} from "./PresaleABI";
export const useCallHook3 = (
  functionName: string,
  arg: any[]
): BigNumber[] | undefined => {
  const { chainId } = useEthers();
  const currentNetwork = useSupportedNetworks3[chainId!];

  const { value, error } =
    useCall(
      currentNetwork?.presaleAddress && {
        contract: currentNetwork?.presaleContractInterface,
        method: functionName,
        args: arg ?? [],
      }
    ) ?? {};
  if (error) {
    console.error("Presale Hook Errors", error.message);
  }

  return value;
};

export const usePresalePrice3 = () => {
  const value: BigNumber[] | undefined = useCallHook3("getPrice", []);
  const valueFormatted = value ? Number(formatEther(value?.[0])) : 0;
  return valueFormatted;
};
export const useBNBcollected =  async (): Promise<BigNumber | undefined> => {
  const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");

  const contractAddress = "0x542F6f6C688ec14B750E68C7C7383276A7d19100";

  try {
    

    const contract = new ethers.Contract(contractAddress, PresaleABI, provider);
    const [ethCollected] = await contract.getPresaleAnalytics();
    return ethCollected;
  } catch (error) {
    console.error("Presale Analytics Error", error);
    return undefined;
  }
};